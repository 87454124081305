body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.u-section-1 .u-sheet-1 {
  min-height: 200px;
}

.u-section-1 .u-list-1 {
  grid-template-rows: repeat(1, auto);
  margin: 70px 0 60px;
}

.u-section-1 .u-repeater-1 {
  grid-template-columns: repeat(5, calc(20% - 8px));
  min-height: 375px;
  grid-auto-columns: calc(20% - 8px);
  grid-gap: 10px;
}

.u-section-1 .u-image-1 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('https://assets.nicepagecdn.com/013e3c16/5250620/images/nft0.png');
  background-position: 50% 50%;
}

.u-section-1 .u-container-layout-1 {
  padding: 30px;
}

.u-section-1 .u-text-1 {
  margin-left: 0;
  margin-bottom: 0;
}

.u-section-1 .u-text-2 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.u-section-1 .u-image-2 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('https://assets.nicepagecdn.com/013e3c16/5250620/images/nft1.png');
  background-position: 50% 50%;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px;
}

.u-section-1 .u-text-3 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 270px;
}

.u-section-1 .u-text-4 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.u-section-1 .u-image-3 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('https://assets.nicepagecdn.com/013e3c16/5250620/images/nft2.png');
  background-position: 50% 50%;
}

.u-section-1 .u-container-layout-3 {
  padding: 30px;
}

.u-section-1 .u-text-5 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 270px;
}

.u-section-1 .u-text-6 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.u-section-1 .u-image-4 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('https://assets.nicepagecdn.com/013e3c16/5250620/images/nft3.png');
  background-position: 50% 50%;
}

.u-section-1 .u-container-layout-4 {
  padding: 30px;
}

.u-section-1 .u-text-7 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 270px;
}

.u-section-1 .u-text-8 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.u-section-1 .u-image-5 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url('https://assets.nicepagecdn.com/013e3c16/5250620/images/nft4.png');
  background-position: 50% 50%;
}

.u-section-1 .u-container-layout-5 {
  padding: 30px;
}

.u-section-1 .u-text-9 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 270px;
}

.u-section-1 .u-text-10 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 515px;
  }

  .u-section-1 .u-list-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-repeater-1 {
    grid-template-columns: repeat(3, calc(33.3333% - 6.66667px));
    min-height: 307px;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 916px;
  }

  .u-section-1 .u-repeater-1 {
    grid-template-columns: repeat(2, calc(50% - 5px));
    min-height: 806px;
    grid-auto-columns: calc(33.333333333333336% - 6.666666666666667px);
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-repeater-1 {
    grid-template-columns: repeat(1, 100%);
    min-height: 1376px;
    grid-auto-columns: calc(50% - 5px);
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-repeater-1 {
    grid-auto-columns: calc(100% - 0px);
  }
}
